import type { ObtainTokenRequest } from '@qogita/canary-types';
import { Button } from '@qogita/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

import { ErrorBox, SuccessBox } from '#components/Boxes';
import { FormikTextField } from '#components/Form';
import { NextAnchor } from '#components/NextAnchor';
import { QogitaLogoWordmark } from '#components/QogitaLogo';
import { DeactivatedUserError, useLoginUser } from '#hooks/api/useLoginUser';
import { useUnauthenticatedPage } from '#hooks/useUnauthenticatedPage';
import { getFlags } from '#lib/featureFlag';
import { useAnalytics } from '#lib/report/AnalyticsProvider';
import { useTrackEvent } from '#lib/report/tracking';

const loginFormValidationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .email('Must be a valid email address'),
  password: Yup.string().required('Password is required'),
});

const querySchema = Yup.object({
  referrer: Yup.string().optional().default(''),
});

const LoginError = ({ error }: { error: Error | DeactivatedUserError }) => {
  if (error instanceof DeactivatedUserError) {
    return (
      <>
        Your account has been deactivated. Please contact{' '}
        <a
          className="hover:text-error-800 text-inherit underline"
          href="mailto:support@qogita.com"
        >
          support@qogita.com
        </a>{' '}
        to reactivate your account.
      </>
    );
  }

  return <>{error.message}</>;
};

const LoginPage = (): JSX.Element => {
  useUnauthenticatedPage();
  const { query } = useRouter();
  const { referrer } = querySchema.validateSync(query);
  const { mutateAsync: login, error } = useLoginUser();
  const { identify } = useAnalytics();
  const { trackUserSignedIn } = useTrackEvent();

  const handleSubmit = async (
    { email, password }: ObtainTokenRequest,
    { setSubmitting }: FormikHelpers<ObtainTokenRequest>,
  ) => {
    try {
      const { user } = await login({
        email,
        password,
      });

      identify(user);
      trackUserSignedIn({ email: user.email });
    } catch {
      // Continue.
    }
    setSubmitting(false);
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-4">
      <Head>
        <title>Login</title>
        <meta name="description" content="" />
      </Head>
      <strong>
        <Link href="/">
          <QogitaLogoWordmark
            scheme="black"
            className="mb-4 h-[2.5rem] w-full"
          />
        </Link>
      </strong>
      <div className="w-full max-w-xs">
        {referrer === 'register' ? (
          <SuccessBox className="mx-4 mb-6 p-4">
            <p>
              Your account was created successfully, and you can now login.
              Please check your email for instructions on how to verify.
            </p>
          </SuccessBox>
        ) : null}
        {referrer === 'reset-password' ? (
          <SuccessBox className="mx-4 mb-6 p-4">
            <p>Your password was reset successfully, and you can now login.</p>
          </SuccessBox>
        ) : null}
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={loginFormValidationSchema}
        >
          {({ isSubmitting }) => (
            <Form className="mb-6 flex flex-col gap-2 rounded bg-white p-4 shadow-md">
              <FormikTextField
                name="email"
                autoComplete="username"
                required
                label="Email address"
              />
              <FormikTextField
                name="password"
                type="password"
                required
                autoComplete="current-password"
                label="Password"
              />
              {error ? (
                <ErrorBox className="mb-4 p-2 text-sm">
                  <LoginError error={error} />
                </ErrorBox>
              ) : null}
              <Button
                type="submit"
                className="mb-4 w-full"
                disabled={isSubmitting}
              >
                Login
              </Button>
              <div className="text-center">
                <NextAnchor
                  href="/reset-password/"
                  className="whitespace-nowrap"
                >
                  Forgot password
                </NextAnchor>
              </div>
            </Form>
          )}
        </Formik>
        <div className="mb-2 text-center">
          New to Qogita?{' '}
          <NextAnchor
            href={{
              pathname: '/register/',
            }}
            className="whitespace-nowrap"
          >
            Create account
          </NextAnchor>
        </div>
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const featureFlagState = await getFlags();
  return { props: { featureFlagState } };
};

export default LoginPage;
