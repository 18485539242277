import {
  DeprecatedField,
  type DeprecatedFormFieldParam,
  DeprecatedSelect,
  useDeprecatedFormField,
} from '@qogita/ui';
import { useField } from 'formik';
import { type ReactNode } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type FormikSelectFieldProps = DeprecatedFormFieldParam & {
  children: ReactNode;
  placeholder?: string;
  className?: string;
  onBlur?: () => void;
  onChange?: () => void;
  renderDisplayValue?: (value: string) => string | null;
};

/**
 * @deprecated It is recommended to use FormSelectField with react-hook-form instead
 */
export const FormikSelectField = ({
  label,
  name,
  children,
  className,
  placeholder,
  onBlur,
  onChange,
  renderDisplayValue,
  ...rest
}: FormikSelectFieldProps) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField(name);

  const { fieldProps, labelProps, controlProps, fieldErrorProps } =
    useDeprecatedFormField({
      ...rest,
      fieldset: false,
      label,
      name,
      errorMessage: touched && error,
    });

  const handleChange = (value: string) => {
    setValue(value);
    onChange?.();
  };

  const handleBlur = () => {
    setTouched(true);
    onBlur?.();
  };

  return (
    <DeprecatedField {...fieldProps} className={className}>
      <DeprecatedSelect
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <DeprecatedSelect.Label {...labelProps} as={DeprecatedField.Label} />

        <DeprecatedSelect.Button {...controlProps} placeholder={placeholder}>
          {renderDisplayValue ? renderDisplayValue(value) : value}
        </DeprecatedSelect.Button>

        <DeprecatedSelect.Options>
          <>{children}</>
        </DeprecatedSelect.Options>
      </DeprecatedSelect>

      {fieldErrorProps ? <DeprecatedField.Error {...fieldErrorProps} /> : null}
    </DeprecatedField>
  );
};

FormikSelectField.Option = DeprecatedSelect.Option;

/*
  Must be used inside a FormProvider, otherwise control prop needs to be passed
*/
type FormSelectFieldProps = DeprecatedFormFieldParam & {
  children: ReactNode;
  placeholder?: string;
  className?: string;
  onBlur?: () => void;
  onChange?: () => void;
  renderDisplayValue?: (value: string) => string | null;
} & UseControllerProps;

export const FormSelectField = ({
  label,
  name,
  children,
  className,
  placeholder,
  onBlur,
  onChange,
  renderDisplayValue,
  control,
  ...rest
}: FormSelectFieldProps) => {
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    ...(control ? { control } : {}),
  });

  const value = field.value;
  const error = errors[name]?.message as string;

  const { fieldProps, labelProps, controlProps, fieldErrorProps } =
    useDeprecatedFormField({
      ...rest,
      fieldset: false,
      label,
      name,
      errorMessage: error,
    });

  const handleChange = (newValue: string) => {
    field.onChange(newValue);
    onChange?.();
  };

  const handleBlur = () => {
    field.onBlur();
    onBlur?.();
  };

  return (
    <DeprecatedField {...fieldProps} className={className}>
      <DeprecatedSelect
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <DeprecatedSelect.Label {...labelProps} as={DeprecatedField.Label} />

        <DeprecatedSelect.Button {...controlProps} placeholder={placeholder}>
          {renderDisplayValue ? renderDisplayValue(field.value) : value}
        </DeprecatedSelect.Button>

        <DeprecatedSelect.Options>
          <>{children}</>
        </DeprecatedSelect.Options>
      </DeprecatedSelect>

      {fieldErrorProps ? <DeprecatedField.Error {...fieldErrorProps} /> : null}
    </DeprecatedField>
  );
};

FormSelectField.Option = DeprecatedSelect.Option;
