import {
  DeprecatedFormField,
  type DeprecatedFormFieldInputParam,
} from '@qogita/ui';
import { useField } from 'formik';
import { useController, UseControllerProps } from 'react-hook-form';

type FormikTextFieldProps = Omit<
  DeprecatedFormFieldInputParam,
  'value' | 'onChange'
> & {
  onChange?: DeprecatedFormFieldInputParam['onChange'];
};

/**
 * @deprecated It is recommended to use FormTextField with react-hook-form instead
 */
export function FormikTextField(props: FormikTextFieldProps) {
  const [field, meta] = useField(props.name);

  return (
    <DeprecatedFormField
      {...props}
      {...field}
      errorMessage={meta.touched && meta.error}
      onBlur={(event) => {
        props.onBlur?.(event);
        field.onBlur(event);
      }}
      onChange={(event) => {
        props.onChange?.(event);
        field.onChange(event);
      }}
    />
  );
}

type FormTextFieldProps = Omit<
  DeprecatedFormFieldInputParam,
  'value' | 'onChange'
> & {
  onChange?: DeprecatedFormFieldInputParam['onChange'];
} & UseControllerProps;

/*
  Must be used inside a FormProvider, otherwise control prop needs to be passed
*/
export function FormTextField(props: FormTextFieldProps) {
  const fieldName = props.name;
  const {
    field,
    formState: { errors },
  } = useController({
    name: fieldName,
    ...(props.control ? { control: props.control } : {}),
  });

  return (
    <DeprecatedFormField
      {...props}
      {...field}
      errorMessage={errors[fieldName]?.message as string}
      onBlur={(event) => {
        props.onBlur?.(event);
        field.onBlur();
      }}
      onChange={(event) => {
        props.onChange?.(event);
        field.onChange(event);
      }}
    />
  );
}
