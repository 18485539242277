import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import {
  DeprecatedField,
  type DeprecatedFormFieldInputParam,
  DeprecatedFormInput,
  DeprecatedInputEndAdornment,
  useDeprecatedFormField,
} from '@qogita/ui';
import { useField } from 'formik';
import { useState } from 'react';

type PasswordFieldProps = Omit<
  DeprecatedFormFieldInputParam,
  'type' | 'endAdornment'
>;

const visibilityConfig = {
  visible: {
    type: 'text',
    ariaLabel: 'Hide password',
    title: 'Hide password',
    Icon: EyeSlashIcon,
  },
  hidden: {
    type: 'password',
    ariaLabel: 'Show password',
    title: 'Show password',
    Icon: EyeIcon,
  },
} as const;

const PasswordField = ({
  id,
  name,
  label,
  description,
  required,
  disabled,
  errorMessage,
  ...props
}: PasswordFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { Icon, type, title, ariaLabel } =
    visibilityConfig[isPasswordVisible ? 'visible' : 'hidden'];

  const {
    fieldProps,
    labelProps,
    descriptionProps,
    controlProps,
    fieldErrorProps,
  } = useDeprecatedFormField({
    id,
    name,
    label,
    description,
    errorMessage,
    required,
    disabled,
    fieldset: false,
  });

  return (
    <DeprecatedField {...fieldProps}>
      {'htmlFor' in labelProps ? (
        <DeprecatedField.Label {...labelProps} />
      ) : null}

      {descriptionProps ? (
        <DeprecatedField.Description {...descriptionProps} />
      ) : null}

      {controlProps ? (
        <DeprecatedFormInput
          {...props}
          {...controlProps}
          type={type}
          className="pr-11"
          endAdornment={
            <DeprecatedInputEndAdornment variant="end" className="px-3">
              <button
                type="button"
                title={title}
                aria-label={ariaLabel}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <Icon className="h-5 w-5 text-gray-400" />
              </button>
            </DeprecatedInputEndAdornment>
          }
        />
      ) : null}

      {fieldErrorProps ? <DeprecatedField.Error {...fieldErrorProps} /> : null}
    </DeprecatedField>
  );
};

type FormPasswordFieldProps = Omit<
  DeprecatedFormFieldInputParam,
  'value' | 'onChange'
> & {
  onChange?: DeprecatedFormFieldInputParam['onChange'];
};

export function FormPasswordField(props: FormPasswordFieldProps) {
  const [field, meta, helpers] = useField(props.name);

  return (
    <PasswordField
      {...props}
      {...field}
      errorMessage={meta.touched && meta.error}
      onBlur={(event) => {
        props.onBlur?.(event);
        helpers.setTouched(true);
      }}
      onChange={(event) => {
        props.onChange?.(event);
        helpers.setValue(event.target.value);
      }}
    />
  );
}
