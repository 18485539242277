import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { useAuthentication } from '#contexts/Authentication';
import { environment } from '#lib/environment.mjs';

const DEFAULT_REDIRECT = `/products/`;
const VERIFY_REDIRECT = `/verify/`;
const BASE_URL = environment.NEXT_PUBLIC_BASE_URL;

const querySchema = Yup.object({
  redirectTo: Yup.string().optional().default(DEFAULT_REDIRECT),
});

/**
 * Redirects users who are already authenticated and shouldn't
 * access this page. E.g. `/login/`, `/register/` etc
 *
 * Redirects them to the `redirectTo` url query param if present,
 * or `/products/` if not.
 *
 * After registration this redirects the user to the /verify page.
 */
export const useUnauthenticatedPage = () => {
  const { status } = useAuthentication();
  const { replace, query, pathname } = useRouter();
  const { redirectTo } = querySchema.validateSync(query);

  const baseUrl = new URL(BASE_URL ?? '');
  const redirectUrl = new URL(redirectTo, baseUrl);

  useEffect(() => {
    if (status === 'authenticated' && redirectUrl.origin === baseUrl.origin) {
      replace(redirectTo);
    } else if (status === 'authenticated' && pathname.includes('/register')) {
      replace(VERIFY_REDIRECT);
    } else if (status === 'authenticated') {
      replace(DEFAULT_REDIRECT);
    }
  }, [
    status,
    replace,
    pathname,
    redirectTo,
    redirectUrl.origin,
    baseUrl.origin,
  ]);
};
